<script>
import { MEMORY_END_TIMEOUT } from '@/enums/quest-basis-config';
import state from '@/components/ui-memory/state';

export default {
  data() {
    return {
      state,
      record: { questTime: this.time },
      selected: [],
      startTime: undefined,
    };
  },
  beforeDestroy() {
    this.$root.setAnswer({
      correct: this.selected.length === this.elementsToRemember && this.selected.every(({ object }) => object.correct),
      correctSelected: this.selected.filter(({ object }) => object.correct).length,
      uncorrectSelected: this.selected.filter(({ object }) => !object.correct).length,
      records: this.elementsToRemember,
    });
  },
  mounted() {
    this.startTime = Date.now();
  },
  computed: {
    correct() {
      return this.objects.find(({ correct }) => correct);
    },
    uncorrect() {
      return this.objects.find(({ correct }) => !correct);
    },
  },
  methods: {
    select({ target }, record) {
      if (this.selected.includes(record)) {
        return;
      }

      if (this.selected.length >= this.elementsToRemember) {
        return;
      }

      target.src = this.correct.object_image;
      this.selected.push(record);
      if (this.selected.length === this.elementsToRemember) {
        setTimeout(() => this.$root.skip(), MEMORY_END_TIMEOUT);
      }
    },
  },
};
</script>
