<script>
import { TimeoutTimer } from '@/libs/timer';

export default {
  data() {
    return {
      timer: undefined,
    };
  },
  beforeDestroy() {
    this.timer?.destroy();
    this.fuseTimer?.destroy();
  },
  mounted() {
    this.questTime = this.record?.questTime;
    if (this.questTime) {
      const questTime = this.questTime * 1000;
      this.timer = new TimeoutTimer(() => this.$root.skip(), questTime);

      if (this.questFuse) {
        this.fuseTimer = new TimeoutTimer(() => this.$root.$emit('fuse'), questTime - this.questFuse);
      }
    }
  },
};
</script>
