import { getPausedTime } from '@/libs/timer';

export default class Flower {
  constructor(canvas, src, srcIncorrect, speedMin, speedMax, speedBasis, { width, height }) {
    this.canvas = canvas;
    this.canvasWidth = width;
    this.canvasHeight = height;
    this.src = src;
    this.srcIncorrect = srcIncorrect;
    this.context = this.canvas.getContext('2d');
    this.speedMin = speedMin;
    this.speedMax = speedMax;
    this.speedBasis = speedBasis;
    this.dx = 0.0005 * this.canvasWidth;
    this.dy = 0.0005 * this.canvasHeight;
    this.result = { correct: 0, incorrect: 0 };
    this.startTime = Date.now();
    this.flower = {
      correct: new Image(),
      incorrect: new Image(),
    };
  }

  get circle() {
    return {
      left: this.data.left + this.data.width / 2,
      top: this.data.top + this.data.height / 2,
      radius: 0.3 * this.data.width / 2,
    };
  }

  get percentage() {
    return Math.floor(this.result.correct / (this.result.correct + this.result.incorrect) * 100);
  }

  async create() {
    this.flower.correct.src = this.src;
    this.flower.incorrect.src = this.srcIncorrect;

    return (await Promise.all([
        new Promise(((resolve) => {
          this.flower.correct.onload = () => {
            const { width: imageWidth, height: imageHeight } = this.flower.correct;
            const aspect = imageWidth / imageHeight;
            const width = this.canvasWidth < this.canvasHeight ? 0.5 * this.canvasWidth : 0.5 * this.canvasHeight;
            const height = width / aspect;
            const top = this.canvasHeight / 2 - height / 2;
            const left = this.canvasWidth / 2 - width / 2;

            this.data = { left, top, width, height };
            resolve();
          };
        })),
        this.srcIncorrect && new Promise(((resolve) => this.flower.incorrect.onload = () => resolve())),
      ].filter(Boolean),
    ));
  }

  render(colliding) {
    const image = colliding || !this.srcIncorrect ? this.flower.correct : this.flower.incorrect;
    this.result[colliding ? 'correct' : 'incorrect'] += 1;
    this.context.drawImage(image, this.data.left, this.data.top, this.data.width, this.data.height);

    this.context.beginPath();
    this.context.arc(this.circle.left, this.circle.top, this.circle.radius, 0, 2 * Math.PI);
    this.context.strokeStyle = 'transparent';
    this.context.stroke();
  }

  checkCollision(position) {
    if (!position) {
      return;
    }

    const { top, left, width, height } = position;
    const distX = Math.abs(this.circle.left - left - width / 2);
    const distY = Math.abs(this.circle.top - top - height / 2);

    if (distX > (width / 2 + this.circle.radius)) {
      return false;
    }

    if (distY > (height / 2 + this.circle.radius)) {
      return false;
    }

    if (distX <= width / 2) {
      return true;
    }

    if (distY <= height / 2) {
      return true;
    }

    const dx = distX - width / 2;
    const dy = distY - height / 2;
    return (dx * dx + dy * dy <= (this.circle.radius * this.circle.radius));
  }

  move(position) {
    if (!this.data) {
      return;
    }

    const { left, top, width, height } = this.data;
    if (left < 0 || left > this.canvasWidth - width) {
      this.dx = -this.dx;
    }

    if (top < 0 || top > this.canvasHeight - height) {
      this.dy = -this.dy;
    }

    const speed = this.speedMax
      ? this.speedMin + Math.floor((Date.now() - this.startTime + getPausedTime()) / 1000) * this.speedBasis
      : this.speedMin || 1;
    this.data.left += this.dx * speed;
    this.data.top += this.dy * speed;

    const colliding = this.checkCollision(position);
    this.render(colliding);
  }
}
