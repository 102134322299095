<template>
  <div v-if="record"
      :style="style"
      class="view__headline"
      v-html="title || record.title"
  ></div>
</template>
<script>
export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
    title: String,
  },
  computed: {
    style() {
      return {
        'background-color': this.record.backgroundColor || '#ee527f',
        'color': this.record.textColor || '#fff',
      };
    },
  },
};
</script>
