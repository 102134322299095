<template>
  <div class="view view--memory">
    <ui-headline v-if="record.headline" :record="record.headline" :title="title"/>
    <ui-counter :amount="amount" :record="record.headline"/>
    <div class="view__body-container" :style="style">
      <component
          :is="layout"
          :elements-to-remember="elementsToRemember"
          :elements-total="elementsTotal"
          :objects="record.body.objects"
          :quest-time="record.questTime"
          :is-intro-view="isIntroView"
      />
    </div>
  </div>
</template>
<script>
import { StyleMixin } from '@/mixins';
import UiCounter from '@/components/ui-counter/index.vue';
import UiHeadline from '@/components/ui-headline/index.vue';
import UiMemoryRandom from '@/components/ui-memory/ui-memory-random/index.vue';
import UiMemoryBox from '@/components/ui-memory/ui-memory-box/index.vue';
import { addQuest } from '@/app/state';
import { RANDOM } from '@/enums/memory-type';
import { TimeoutTimer } from '@/libs/timer';

export default {
  components: {
    UiCounter,
    UiHeadline,
  },
  mixins: [
    StyleMixin,
  ],
  props: {
    record: {
      type: Object,
      required: true,
    },
    amount: Number,
  },
  data() {
    return {
      elementsToRemember: parseFloat(this.record.elementsToRemember),
      elementsTotal: parseFloat(this.record.elementsTotal),
      isIntroView: true,
      timer: undefined,
    };
  },
  computed: {
    layout() {
      if (this.record.elementsPosition === RANDOM) {
        return UiMemoryRandom;
      }

      return UiMemoryBox;
    },
    title() {
      return this.isIntroView ? this.record.headline.title : this.record.body.quest;
    },
  },
  beforeDestroy() {
    this.timer?.destroy();
  },
  mounted() {
    addQuest({ testId: this.record.testId, type: this.record.type });
    this.timer = new TimeoutTimer(() => this.isIntroView = false, this.record.introTime * 1000);
  },
};
</script>
