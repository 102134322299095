<template>
  <canvas ref="canvas" :width="width" :height="height"></canvas>
</template>
<script>
import { PRECISION_JOYSTICK_HEIGHT } from '@/enums/quest-basis-config';
import { Game } from '@/components/ui-precision/components';

export default {
  props: {
    objects: {
      type: Array,
      required: true,
    },
    time: {
      type: Number,
      required: true,
    },
    questImage: {
      type: String,
      required: true,
    },
    questImageIncorrect: String,
    position: {
      type: Object,
      required: true,
    },
    speedMin: Number,
    speedMax: Number,
  },
  data() {
    return {
      width: 0,
      height: 0,
      speedBasis: this.speedMax ? (this.speedMax - this.speedMin) / this.time : 1,
    };
  },
  beforeDestroy() {
    this.$root.$off('pause', this.pause);
    this.$root.$off('resume', this.resume);
    cancelAnimationFrame(this.requestId);
    this.$root.setAnswer({
      correct: this.game.result || 0,
    });

    this.game.destroy();
  },
  created() {
    this.$root.$on('pause', this.pause);
    this.$root.$on('resume', this.resume);
  },
  mounted() {
    const { offsetWidth, offsetHeight } = this.$parent.$el.querySelector('.view__body-container');
    this.width = offsetWidth;
    this.height = offsetHeight - PRECISION_JOYSTICK_HEIGHT;
    this.game = new Game(
      this.$refs.canvas,
      this.questImage,
      this.questImageIncorrect,
      this.objects,
      this.speedMin,
      this.speedMax,
      this.speedBasis,
      {
        width: this.width,
        height: this.height,
      },
    );

    this.move();
  },
  methods: {
    move() {
      this.game.move(this.position);
      this.requestId = requestAnimationFrame(() => this.move());
    },
    pause() {
      cancelAnimationFrame(this.requestId);
    },
    resume() {
      this.move();
    },
  },
};
</script>
