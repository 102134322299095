<template>
  <div class="view result">
    <ui-headline v-if="headline" :record="headline"/>
    <div v-if="body" class="view__body-container" :style="style">
      <div class="view__body" :style="{ 'background-color': body.textBackroundColor, 'border-color': body.textBorderColor }">
        <img v-if="body.resultIcon" :src="body.resultIcon"/>
        <p v-if="body.resultDescription" v-html="body.resultDescription"></p>
      </div>
    </div>
    <ui-footer v-if="footer" :record="footer"/>
  </div>
</template>
<script>
import { StyleMixin } from '@/mixins';
import UiHeadline from '@/components/ui-headline/index.vue';
import UiFooter from '@/components/ui-footer/index.vue';

export default {
  components: {
    UiHeadline,
    UiFooter,
  },
  mixins: [
    StyleMixin,
  ],
  props: {
    record: {
      type: Object,
      required: true,
    }
  },
  data() {
    const { headline, body, footer } = this.record;

    return {
      headline,
      body,
      footer,
    };
  },
};
</script>
