<script>
export default {
  data() {
    return {
      body: this.record?.body,
    };
  },
  computed: {
    style() {
      if (!this.record?.body) {
        return {};
      }

      const { backgroundColor, backgroundImg, textColor } = this.record?.body;
      return {
        'background-color': backgroundColor,
        'background-image': `url("${backgroundImg}")`,
        'color': textColor,
      };
    },
  },
};
</script>
