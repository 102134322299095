<template>
  <div ref="view" class="fruits__view"></div>
</template>
<script>
import UiFruitsItem from '@/components/ui-fruits/ui-fruits-item.vue';
import { FRUITS_CLEAR_DIFFERENCE, FRUITS_SPEED_MULTIPLIER } from '@/enums/quest-basis-config';
import { IntervalTimer, getPausedTime } from '@/libs/timer';

export default {
  props: {
    objects: {
      type: Array,
      required: true,
    },
    time: {
      type: Number,
      required: true,
    },
    speedMin: Number,
    speedMax: Number,
  },
  data() {
    return {
      records: [],
      results: [],
      speedBasis: (this.speedMax ? (this.speedMax - this.speedMin) / this.time : 1),
      interval: undefined,
    };
  },
  beforeDestroy() {
    this.$root.$off('resume', this.setStartTime);
    this.timer?.destroy();
    this.$root.setAnswer({
      correctSelected: this.results.filter(({ correct }) => correct).length,
      uncorrectSelected: this.results.filter(({ correct }) => !correct).length,
      correctRecords: this.records.filter(({ correct }) => correct).length,
      uncorrectRecords: this.records.filter(({ correct }) => !correct).length,
      records: this.records.length,
    });
  },
  mounted() {
    this.startTime = Date.now();
    this.start();
    this.$root.$on('resume', this.setStartTime);
  },
  methods: {
    start() {
      this.timer = new IntervalTimer(() => {
        const now = Date.now();
        if (this.startTime + this.time * 1000 <= now + FRUITS_CLEAR_DIFFERENCE * 1000) {
          return this.timer?.destroy();
        }

        this.create();
      }, this.time / (this.time - 1) * 1000);
      this.create();
    },
    getObject() {
      return this.objects[Math.floor(Math.random() * this.objects.length)];
    },
    create() {
      const speed = this.speedMax
        ? this.speedMin + Math.floor((Date.now() - this.startTime) / 1000) * this.speedBasis
        : this.speedMin || 1;

      const record = this.getObject();
      const item = new UiFruitsItem({
        propsData: {
          $rootInstance: this.$root,
          $view: this.$refs.view,
          record,
          speed: speed * FRUITS_SPEED_MULTIPLIER,
        },
      });
      item.$on('select', (record) => this.collect(record));
      this.records.push(record);
    },
    collect(record) {
      this.results.push(record);
    },
    setStartTime() {
      this.startTime += getPausedTime();
    },
  },
};
</script>
