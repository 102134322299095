<template>
  <div class="memory__view memory__view--box">
    <template v-if="startTime">
      <img
          v-for="record in state.records"
          :src="uncorrect.object_image"
          :style="getStyle(record)"
          class="memory__view-item--box"
          @click="select($event, record)"
      />
    </template>
  </div>
</template>
<script>
import { TimeMixin } from '@/mixins';
import UiMemoryViewMixin from '@/components/ui-memory/ui-memory-view-mixin.vue';

export default {
  mixins: [
    TimeMixin,
    UiMemoryViewMixin,
  ],
  props: {
    elementsToRemember: Number,
    elementsTotal: Number,
    objects: Array,
    time: Number,
  },
  methods: {
    getStyle() {
      const { offsetWidth, offsetHeight } = this.$el;

      return {
        [offsetWidth < offsetHeight ? 'width' : 'height']: `${Math.floor(100 / Math.sqrt(this.elementsTotal))}%`,
      };
    },
  },
};
</script>
