<template>
  <div id="app" :class="{ 'is-mobile': isMobile }" :style="style">
    <ui-group v-if="activeGroup" :group="activeGroup"/>
    <ui-summary v-if="isSummaryVisible"/>
    <ui-result v-if="result" :record="result"/>
  </div>
</template>
<script>
import Vue from '@vue';
import http from '@/libs/http';
import { setAnswer, skip, results } from '@/app/state';
import { UiGroup, UiSummary, UiResult } from '@/components';
import { pause, resume } from '@/libs/timer';
import { imagesPreload } from '@/utils';

export default Vue.extend({
  components: {
    UiGroup,
    UiSummary,
    UiResult,
  },
  data() {
    return {
      result: undefined,
      activeGroup: undefined,
      isSummaryVisible: false,
      receiver: navigator.userAgent.toLowerCase().match(/\(ip.*applewebkit(?!.*(version|crios))/) ? window : document,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    };
  },
  computed: {
    group() {
      return this.$state.groups[0];
    },
    style() {
      return {
        'background-image': `url("${this.$state.backgroundImg}")`,
        'background-color': this.$state.backgroundColor,
      };
    },
  },
  watch: {
    group: {
      immediate: true,
      async handler() {
        this.activeGroup = undefined;
        await this.$nextTick();
        this.activeGroup = this.group;
        if (!this.group) {
          return this.save();
        }
      },
    },
  },
  beforeDestroy() {
    this.receiver.removeEventListener('message', this.receive);
  },
  created() {
    this.receiver.addEventListener('message', this.receive);
  },
  methods: {
    skip,
    setAnswer(data, skip) {
      setAnswer(data);
      if (skip) {
        this.skip();
      }
    },
    showSummary() {
      this.isSummaryVisible = true;
      pause();
    },
    hideSummary() {
      this.isSummaryVisible = false;
      resume();
    },
    async save() {
      document.querySelector('.loader').classList.remove('loader--hidden');
      const { TEST_ID, APPID, USERDATA } = window;
      const { data } = await http.post('/results/save/', {
        fullTestId: TEST_ID || '1',
        appId: APPID,
        userData: typeof USERDATA === 'string' ? JSON.parse(USERDATA) : USERDATA,
        results,
      });

      this.result = data;
      document.querySelector('.loader').classList.add('loader--hidden');
    },
    receive({ data }) {
      switch (data) {
        case 'pause':
          this.$emit('pause');
          pause();
          break;
        case 'resume':
          this.$emit('resume');
          resume();
          break;
      }
    },
  },
});
</script>
