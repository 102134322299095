<template>
  <div class="memory__view memory__view--random">
    <img
        v-for="record in state.records"
        :src="uncorrect.object_image"
        :style="getStyle(record)"
        class="memory__view-item--random"
        @click="select($event, record)"
    />
  </div>
</template>
<script>
import { TimeMixin } from '@/mixins';
import UiMemoryViewMixin from '@/components/ui-memory/ui-memory-view-mixin.vue';

export default {
  mixins: [
    TimeMixin,
    UiMemoryViewMixin,
  ],
  props: {
    elementsToRemember: Number,
    objects: Array,
    time: Number,
  },
  methods: {
    getStyle({ x, y, width, height }) {
      return {
        top: `${y}px`,
        left: `${x}px`,
        width: `${width}px`,
        height: `${height}px`,
      };
    },
  },
};
</script>
