const COLORS = ['#ffc000', '#ff3b3b', '#ff8400'];
const BUBBLES = 10;
const getRadius = (a, b, c) => parseFloat((Math.random() * ((a ? a : 1) - (b ? b : 0)) + (b ? b : 0)).toFixed(c ? c : 0));

const render = (particles, ctx, width, height) => {
  requestAnimationFrame(() => render(particles, ctx, width, height));
  ctx.clearRect(0, 0, width, height);

  particles.forEach((p) => {
    p.x += p.speed * Math.cos(p.rotation * Math.PI / 180);
    p.y += p.speed * Math.sin(p.rotation * Math.PI / 180);

    p.opacity -= 0.01;
    p.speed *= p.friction;
    p.radius *= p.friction;
    p.yVel += p.gravity;
    p.y += p.yVel;

    if (p.opacity < 0 || p.radius < 0) return;

    ctx.beginPath();
    ctx.globalAlpha = p.opacity;
    ctx.fillStyle = p.color;
    ctx.arc(p.x, p.y, p.radius, 0, 2 * Math.PI, false);
    ctx.fill();
  });

  return ctx;
};

export default (left, top, width, height) => {
  let particles = [];
  let ratio = 2;
  let c = document.createElement('canvas');
  let ctx = c.getContext('2d');

  c.style.position = 'absolute';
  c.style.left = `${left - width}px`;
  c.style.top = `${top - height}px`;
  c.style.width = `${width * 2}px`;
  c.style.height = `${height * 2}px`;
  c.style.zIndex = 100;
  c.width = 100 * ratio;
  c.height = 100 * ratio;
  c.style.pointerEvents = 'none';
  document.body.appendChild(c);

  for (let i = 0; i < BUBBLES; i++) {
    particles.push({
      x: c.width / 2,
      y: c.height / 2,
      radius: getRadius(20, 30),
      color: COLORS[Math.floor(Math.random() * COLORS.length)],
      rotation: getRadius(0, 360, true),
      speed: getRadius(8, 12),
      friction: 0.9,
      opacity: getRadius(0, 0.5, true),
      yVel: 0,
      gravity: 0.1,
    });
  }

  render(particles, ctx, c.width, c.height);
  setTimeout(() => document.body.removeChild(c), 1000);
};
