import Vue from '@vue';
import { clearPausedTime, getPausedTime } from '@/libs/timer';

export const results = [];
export const closeGroup = () => {
  Vue.nextTick(() => {
    Object.assign(results[results.length - 1], {
      endTime: Date.now(),
      time: (results[results.length - 1]?.quests || []).reduce((acc, quest) => {
        return acc + quest.time;
      }, 0),
    });
  });
};
export const openGroup = () => results.push({
  startTime: Date.now(),
  endTime: undefined,
  quests: [],
});
export const addQuest = (attrs) => results[results.length - 1]?.quests.push({
  startTime: Date.now(),
  ...attrs,
});
export const setAnswer = (data) => {
  const quests = results[results.length - 1]?.quests || [];
  const current = quests[quests.length - 1];
  const endTime = Date.now();
  Object.assign(quests[quests.length - 1], {
    endTime,
    time: endTime - current.startTime - getPausedTime(),
    ...data,
  });
};

export const setState = (_state) => Object.assign(state, _state);
export const skipGame = () => {
  while (state.groups.length) {
    skipGroup();
  }
};
export const skipGroup = () => state.groups.shift();
export const skip = () => {
  setTimeout(() => clearPausedTime(), 0);
  if (!(state.groups[0]?.elements.length > 1)) {
    return skipGroup();
  }

  state.groups[0].elements.shift();
};

const state = Vue.observable({
  appId: '',
  backgroundColor: '',
  backgroundImg: '',
  deviceType: 'mobile',
  groups: [],
  userData: {},
});

export default state;
