const INDEX_CHANGE_INTERVAL = 100; // [ms]

export default class Bee {
  constructor(canvas, objects, { width, height }) {
    this.canvas = canvas;
    this.canvasWidth = width;
    this.canvasHeight = height;
    this.objects = objects;
    this.records = [];
    this.context = this.canvas.getContext('2d');
    this.index = 0;
    this.interval = undefined;
  }

  setIndex() {
    this.index++;
    if (this.index >= this.records.length) {
      this.index = 0;
    }
  }

  create() {
    const images = this.objects.map(({ object_image }) => object_image);
    this.interval = setInterval(() => this.setIndex(), INDEX_CHANGE_INTERVAL);

    return Promise.all(images.map((src) => new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        const { width: imageWidth, height: imageHeight } = image;
        const aspect = imageWidth / imageHeight;
        const width = this.canvasWidth < this.canvasHeight ? 0.25 * this.canvasWidth : 0.25 * this.canvasHeight;
        const height = width / aspect;
        const top = this.canvasHeight / 2 - height / 2;
        const left = this.canvasWidth / 2 - width / 2;

        this.data = { left, top, width, height };
        this.records.push(image);
        resolve();
      };
      image.src = src;
    })));
  }

  render({ x = 0, y = 0 } = {}) {
    if (!this.data) {
      return;
    }

    this.data.left += x;
    this.data.top += y;
    if (this.data.left < 0) {
      this.data.left = 0;
    }

    if (this.data.left > this.canvasWidth - this.data.width) {
      this.data.left = this.canvasWidth - this.data.width;
    }

    if (this.data.top < 0) {
      this.data.top = 0;
    }

    if (this.data.top > this.canvasHeight - this.data.height) {
      this.data.top = this.canvasHeight - this.data.height;
    }

    this.context.drawImage(this.records[this.index], this.data.left, this.data.top, this.data.width, this.data.height);
  }

  destroy() {
    clearInterval(this.interval);
  }
}
