<template>
  <div class="view view--fruits">
    <ui-headline v-if="record.headline" :record="record.headline"/>
    <ui-counter :amount="amount" :record="record.headline"/>
    <div class="view__body-container" :style="style">
      <ui-fruits-view :objects="objects" :speedMin="speedMin" :speedMax="speedMax" :time="time"/>
    </div>
  </div>
</template>
<script>
import { StyleMixin, TimeMixin } from '@/mixins';
import UiCounter from '@/components/ui-counter/index.vue';
import UiHeadline from '@/components/ui-headline/index.vue';
import UiFruitsView from '@/components/ui-fruits/ui-fruits-view.vue';
import { addQuest } from '@/app/state';

export default {
  components: {
    UiCounter,
    UiHeadline,
    UiFruitsView,
  },
  mixins: [
    StyleMixin,
    TimeMixin,
  ],
  props: {
    record: {
      type: Object,
      required: true,
    },
    amount: Number,
  },
  data() {
    return {
      speedMin: parseFloat(this.record.speedMin),
      speedMax: parseFloat(this.record.speedMax),
      objects: this.record.body.objects,
      time: this.record.questTime,
    };
  },
  mounted() {
    addQuest({ testId: this.record.testId, type: this.record.type });
  },
};
</script>
