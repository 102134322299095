import Bee from './bee';
import Flower from './flower';

export default class Game {
  constructor(canvas, questImage, questImageIncorrect, objects, speedMin, speedMax, speedBasis, { width, height }) {
    this.canvas = canvas;
    this.width = width;
    this.height = height;
    this.context = this.canvas.getContext('2d');
    this.bee = new Bee(canvas, objects, { width, height });
    this.flower = new Flower(canvas, questImage, questImageIncorrect, speedMin, speedMax, speedBasis, { width, height });

    this.create();
  }

  get result() {
    return this.flower?.percentage;
  }

  async create() {
    await Promise.all([this.bee.create(), this.flower.create()]);

    this.render();
  }

  render() {
    this.flower.render();
    this.bee.render();
  }

  move(position) {
    this.context.clearRect(0, 0, this.width, this.height);
    this.flower.move(this.bee.data);
    this.bee.render(position);
  }

  destroy() {
    this.bee.destroy();
  }
}
