<template>
  <div class="memory__view memory__view--random">
    <img
        v-for="record in state.records"
        :src="record.object.object_image"
        :style="getStyle(record)"
        class="memory__view-item--random"
    />
  </div>
</template>
<script>
import state, { initState } from '@/components/ui-memory/state';

export default {
  props: {
    elementsToRemember: Number,
    elementsTotal: Number,
    objects: Array,
  },
  data() {
    return {
      state,
    };
  },
  mounted() {
    this.startTime = Date.now();
    initState({
      $el: this.$el,
      amount: this.elementsTotal,
      active: this.elementsToRemember,
      objects: this.objects,
    });
  },
  methods: {
    getStyle({ x, y, width, height }) {
      return {
        top: `${y}px`,
        left: `${x}px`,
        width: `${width}px`,
        height: `${height}px`,
      };
    },
  },
};
</script>
