<template>
  <img :src="src" class="fruits__view-item" @click="select"/>
</template>
<script>
import Vue from '@vue';
import { explode } from '@/utils';
import { getPausedTime } from '@/libs/timer';

const FRAME_DURATION = 1000 / 60;
const DEFAULT_HEIGHT = 400;

export default Vue.extend({
  props: {
    record: {
      type: Object,
      required: true,
    },
    speed: Number,
    $view: HTMLElement,
    $rootInstance: Object,
  },
  data() {
    return {
      lastUpdate: Date.now(),
      position: {
        x: Math.random() * (0.8 * window.innerWidth),
        y: 0,
      },
      src: undefined,
      paused: false,
    };
  },
  beforeDestroy() {
    this.$rootInstance.$off('pause', this.pause);
    this.$rootInstance.$off('resume', this.resume);
  },
  created() {
    this.$mount();
    this.$view.appendChild(this.$el);
    this.$rootInstance.$on('pause', this.pause);
    this.$rootInstance.$on('resume', this.resume);
  },
  mounted() {
    const image = new Image();
    image.src = this.record.object_image;
    image.onload = () => {
      const height = image.height / image.width * 0.2 * window.innerWidth;
      this.position.y = -height;
      this.$el.style.left = `${this.position.x}px`;
      this.$el.style.top = `-${height}px`;
      this.src = this.record.object_image;
      this.animate();
    };
  },
  methods: {
    select() {
      this.$emit('select', this.record);
      this.destroy();
    },
    animate() {
      if (this.paused) {
        return;
      }

      const { bottom } = this.$el.getBoundingClientRect();
      if (bottom >= window.innerHeight) {
        return this.destroy();
      }

      const now = Date.now();
      const delta = (now - this.lastUpdate) / FRAME_DURATION * window.innerHeight / DEFAULT_HEIGHT;
      this.position.y += this.speed * delta;
      this.$el.style.transform = `translateY(${this.position.y}px)`;
      this.lastUpdate = now;

      window.requestAnimationFrame(this.animate);
    },
    destroy() {
      const { left, top, width, height } = this.$el.getBoundingClientRect();
      explode(left + width / 2, top + height / 2, width, height);

      this.$destroy();
      this.$view.removeChild(this.$el);
    },
    pause() {
      this.paused = true;
    },
    resume() {
      this.paused = false;
      this.lastUpdate += getPausedTime();
      this.animate();
    },
  },
});
</script>
