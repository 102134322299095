<template>
  <div class="precision__joystick-container" :style="{ 'background-color': joystickBackground }">
    <div class="precision__joystick">
      <div class="precision__joystick-arrow-up"></div>
      <div class="precision__joystick-arrow-down"></div>
      <div class="precision__joystick-arrow-left"></div>
      <div class="precision__joystick-arrow-right"></div>
      <div ref="joystick" class="precision__joystick-inner"></div>
    </div>
  </div>
</template>
<script>
import { PRECISION_MAX_DISTANCE } from '@/enums/quest-basis-config';

export default {
  props: {
    position: {
      type: Object,
      required: true,
    },
    joystickBackground: {
      type: String,
      default: '#41B5A0',
    },
  },
  data() {
    return {
      isActive: false,
      dragStart: undefined,
      touchId: undefined,
    };
  },
  beforeDestroy() {
    this.$refs.joystick.removeEventListener('mousedown', this.onMouseDown);
    this.$refs.joystick.removeEventListener('touchstart', this.onMouseDown);
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('touchmove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    document.removeEventListener('touchend', this.onMouseUp);
  },
  mounted() {
    this.$refs.joystick.addEventListener('mousedown', this.onMouseDown);
    this.$refs.joystick.addEventListener('touchstart', this.onMouseDown);
    document.addEventListener('mousemove', this.onMouseMove, { passive: false });
    document.addEventListener('touchmove', this.onMouseMove, { passive: false });
    document.addEventListener('mouseup', this.onMouseUp);
    document.addEventListener('touchend', this.onMouseUp);
  },
  methods: {
    reset() {
      this.$emit('update:position', { x: 0, y: 0 });
      this.touchId = undefined;
      this.isActive = false;
    },
    onMouseDown(e) {
      this.isActive = true;
      this.$refs.joystick.style.transition = '0s';
      e.preventDefault();

      if (e.changedTouches) {
        const { clientX, clientY, identifier } = e.changedTouches[0];
        this.dragStart = { x: clientX, y: clientY };
        this.touchId = identifier;
      } else {
        this.dragStart = { x: e.clientX, y: e.clientY };
      }
    },
    onMouseMove(e) {
      if (!this.isActive) {
        return;
      }

      let touchmoveId;
      if (e.changedTouches) {
        for (let i = 0; i < e.changedTouches.length; i++) {
          if (this.touchId === e.changedTouches[i].identifier) {
            touchmoveId = i;
            e.clientX = e.changedTouches[i].clientX;
            e.clientY = e.changedTouches[i].clientY;
          }
        }

        if (isNaN(touchmoveId)) {
          return;
        }
      }

      const xDiff = e.clientX - this.dragStart.x;
      const yDiff = e.clientY - this.dragStart.y;
      const angle = Math.atan2(yDiff, xDiff);
      const distance = Math.min(PRECISION_MAX_DISTANCE, Math.hypot(xDiff, yDiff));
      const xPosition = distance * Math.cos(angle);
      const yPosition = distance * Math.sin(angle);

      this.$refs.joystick.style.transform = `translate3d(${xPosition}px, ${yPosition}px, 0px)`;

      const xPosition2 = distance * Math.cos(angle);
      const yPosition2 = distance * Math.sin(angle);
      const xPositionFixed = xPosition2 + xPosition2 / ((PRECISION_MAX_DISTANCE / window.innerWidth) * 10);
      const yPositionFixed = yPosition2 + yPosition2 / ((PRECISION_MAX_DISTANCE / window.innerHeight) * 10);
      const xPercent = parseFloat((xPositionFixed / PRECISION_MAX_DISTANCE).toFixed(4));
      const yPercent = parseFloat((yPositionFixed / PRECISION_MAX_DISTANCE).toFixed(4));

      this.$emit('update:position', { x: xPercent, y: yPercent });
    },
    onMouseUp(e) {
      if (!this.isActive) {
        return;
      }

      if (e.changedTouches && this.touchId !== e.changedTouches[0].identifier) {
        return;
      }

      this.$refs.joystick.style.transition = '.2s';
      this.$refs.joystick.style.transform = `translate3d(0px, 0px, 0px)`;
      this.reset();
    },
  },
};
</script>
