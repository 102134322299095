<template>
  <div class="view view--precision">
    <ui-headline v-if="record.headline" :record="record.headline"/>
    <ui-counter :amount="amount" :record="record.headline"/>
    <div class="view__body-container" :style="style">
      <ui-precision-view
        :objects="objects"
        :speedMin="speedMin"
        :speedMax="speedMax"
        :time="time"
        :position="position"
        :quest-image="questImage"
        :quest-image-incorrect="questImageIncorrect"
      />
      <ui-precision-joystick :position.sync="position" :joystick-background="joystickBackgroundColor"/>
    </div>
  </div>
</template>
<script>
import { StyleMixin, TimeMixin } from '@/mixins';
import UiCounter from '@/components/ui-counter/index.vue';
import UiHeadline from '@/components/ui-headline/index.vue';
import UiPrecisionView from '@/components/ui-precision/ui-precision-view.vue';
import UiPrecisionJoystick from '@/components/ui-precision/ui-precision-joystick.vue';
import { addQuest } from '@/app/state';

export default {
  components: {
    UiCounter,
    UiHeadline,
    UiPrecisionView,
    UiPrecisionJoystick,
  },
  mixins: [
    StyleMixin,
    TimeMixin,
  ],
  props: {
    record: {
      type: Object,
      required: true,
    },
    amount: Number,
  },
  data() {
    return {
      speedMin: parseFloat(this.record.speedMin),
      speedMax: parseFloat(this.record.speedMax),
      objects: this.record.body.objects,
      questImage: this.record.body.questImage,
      questImageIncorrect: this.record.body.questImage1,
      time: this.record.questTime,
      joystickBackgroundColor: this.record.footer?.backgroundColor,
      position: { x: 0, y: 0 },
    };
  },
  mounted() {
    addQuest({ testId: this.record.testId, type: this.record.type });
  },
};
</script>
