import axios from 'axios';

export default axios.create({
  baseURL: `${window.USERDATA?.url || 'https://liverbrain.pum.edu.pl'}/api`,
  withCredentials: true,
  headers: {
    'X-API-KEY': 123123,
  },
  params: {
    apiKey: 123123,
  },
});
