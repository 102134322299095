<template>
  <ui-memory-intro
      v-if="isIntroView"
      :elements-to-remember="elementsToRemember"
      :elements-total="elementsTotal"
      :objects="objects"
  />
  <ui-memory-view
      v-else
      :objects="objects"
      :elements-to-remember="elementsToRemember"
      :elements-total="elementsTotal"
      :time="questTime"
  />
</template>
<script>
import UiMemoryView from '@/components/ui-memory/ui-memory-box/ui-memory-view.vue';
import UiMemoryIntro from '@/components/ui-memory/ui-memory-box/ui-memory-intro.vue';

export default {
  components: {
    UiMemoryIntro,
    UiMemoryView,
  },
  props: {
    isIntroView: Boolean,
    elementsToRemember: Number,
    elementsTotal: Number,
    objects: Array,
    questTime: Number,
  },
};
</script>
