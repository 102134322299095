<template>
  <div class="summary">
    <p v-for="item in items" :key="item.name">
      <span v-text="`${item.name} :`"></span>
      <span v-text="item.value"></span>
    </p>
    <div class="view__footer" v-text="'Zamknij'" @click="close"></div>
  </div>
</template>
<script>
import { results } from '@/app/state';

export default {
  data() {
    return {
      summary: undefined,
    };
  },
  computed: {
    items() {
      if (!this.summary) {
        return [];
      }

      return Object.keys(this.summary).map((key) => ({
        name: key,
        value: this.summary[key],
      }));
    },
  },
  beforeDestroy() {
    this.$root.$emit('resume');
  },
  created() {
    this.getSummary();
    if (!this.summary) {
      return this.close();
    }

    this.$root.$emit('pause');
  },
  methods: {
    close() {
      this.$root.hideSummary();
    },
    getSummary() {
      const result = results[results.length - 1];
      if (!result) {
        return;
      }

      const quests = result?.quests;
      if (!quests.length) {
        return this.getPreviousSummary();
      }

      const summary = quests?.[quests.length - 1];
      const { startTime, endTime } = result;

      if (summary) {
        this.summary = {
          startTime,
          endTime,
          ...summary,
        };
      }
    },
    getPreviousSummary() {
      const result = results[results.length - 2];
      if (!result) {
        return;
      }

      const quests = result?.quests;
      const summary = quests?.[quests.length - 1];
      const { startTime, endTime } = result;

      if (summary) {
        this.summary = {
          startTime,
          endTime,
          ...summary,
        };
      }
    },
  },
};
</script>
