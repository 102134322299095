<template>
  <div class="view__counter" :style="{ 'color': record.progressBarColor, 'background-color': record.progressBarEmptyColor }">
    <span class="view__counter-progress" v-if="progress" :style="progressStyle"></span>
  </div>
</template>
<script>
export default {
  props: {
    amount: Number,
    record: Object,
  },
  data() {
    return {
      progress: this.record.progress,
    };
  },
  computed: {
    progressStyle() {
      return {
        'width': `${this.progress}%`,
        'background-color': this.record.progressBarBackgroundColor,
      };
    },
  },
};
</script>
