<template>
  <canvas ref="canvas" :width="width" :height="height" @click="onClick"></canvas>
</template>
<script>
import Game from '@/components/ui-mole/components/game';

export default {
  props: {
    moles: {
      type: Array,
      required: true,
    },
    time: {
      type: Number,
      required: true,
    },
    holeImage: {
      type: String,
      required: true,
    },
    hideoutTimeRatio: Number,
    speedMin: Number,
    speedMax: Number,
  },
  data() {
    return {
      width: 0,
      height: 0,
      speedBasis: this.speedMax ? (this.speedMax - this.speedMin) / this.time : 1,
    };
  },
  beforeDestroy() {
    this.$root.$off('pause', this.destroy);
    this.$root.$off('resume', this.resume);
    this.$root.$off('fuse', this.destroy);
    this.destroy();
    this.$root.setAnswer({
      correctSelected: this.game?.results.filter(({ correct }) => correct).length,
      uncorrectSelected: this.game?.results.filter(({ correct }) => !correct).length,
      correctRecords: this.game?.records.filter(({ correct }) => correct).length,
      uncorrectRecords: this.game?.records.filter(({ correct }) => !correct).length,
      records: this.game?.records.length,
    });
  },
  created() {
    this.$root.$on('fuse', this.destroy);
    this.$root.$on('pause', this.destroy);
    this.$root.$on('resume', this.resume);
  },
  mounted() {
    const $container = this.$parent.$el.querySelector('.view__body-container');
    const { offsetWidth, offsetHeight } = $container;
    const { left, top } = $container.getBoundingClientRect();
    this.width = offsetWidth;
    this.height = offsetHeight;
    this.game = new Game(
      this.$refs.canvas,
      this.moles,
      this.holeImage,
      this.hideoutTimeRatio,
      this.speedMin,
      this.speedMax,
      this.speedBasis,
      {
        width: this.width,
        height: this.height,
        top,
        left,
      },
    );
  },
  methods: {
    onClick(e) {
      this.game.select(e);
    },
    destroy() {
      this.game?.destroy();
    },
    resume() {
      this.game?.render();
    },
  },
};
</script>
