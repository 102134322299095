import { getPausedTime } from '@/libs/timer';
import { MOLE_SPEED_MULTIPLIER } from '@/enums/quest-basis-config';
import { explode } from '@/utils';

const MOLES_AMOUNT = 9;
const INTERVAL_BASIS = 1000;
export default class Game {
  constructor(canvas, moles, holeImage, hideoutTimeRatio, speedMin, speedMax, speedBasis, { width, height, top, left }) {
    this.canvas = canvas;
    this.width = width;
    this.height = height;
    this.offsetTop = top;
    this.offsetLeft = left;
    this.context = this.canvas.getContext('2d');
    this.hideoutTimeRatio = hideoutTimeRatio;
    this.moles = moles;
    this.hole = holeImage;
    this.holes = [];
    this.speedMin = speedMin;
    this.speedMax = speedMax;
    this.speedBasis = speedBasis;
    this.startTime = Date.now();
    this.results = [];
    this.records = [];

    this.create();
  }

  getNextTimeout() {
    const speed = (this.speedMax
      ? this.speedMin + Math.floor((Date.now() - this.startTime + getPausedTime()) / 1000) * this.speedBasis
      : this.speedMin || 1);

    return INTERVAL_BASIS / (speed * MOLE_SPEED_MULTIPLIER);
  }

  async create() {
    const moles = this.moles;
    this.moles = [];
    await Promise.all([
      ...moles.map((mole) => {
        return new Promise((resolve) => {
          const image = new Image();
          image.onload = () => {
            const { width: imageWidth, height: imageHeight } = image;
            this.aspect = imageWidth / imageHeight;
            this.moles.push({
              ...mole,
              image,
            });
            resolve();
          };
          image.src = mole.mole_image;
        });
      }),
      new Promise((resolve) => {
        const image = new Image();
        image.onload = () => {
          this.hole = image;
          resolve();
        };
        image.src = this.hole;
      }),
    ]);

    this.prepare();
  }

  prepare() {
    const delimiterX = this.width < this.height ? 3 : 5;
    const delimiterY = this.width < this.height ? { top: 3, height: 3 } : { top: 5, height: 2 };
    for (let i = 0; i < MOLES_AMOUNT; i++) {
      this.holes.push({
        left: (i % delimiterX) * (this.width / delimiterX),
        top: Math.floor(i / delimiterY.top) * (this.height / delimiterY.height),
        width: this.width / delimiterX,
        height: this.height / delimiterY.height,
        image: this.hole,
        empty: true,
        correct: false,
      });
    }

    this.nextFillDate = Date.now() + this.getNextTimeout();
    this.render();
  }

  fill() {
    const mole = this.moles[Math.floor(Math.random() * this.moles.length)];
    const indexes = this.holes.reduce((acc, { empty, releasedDate }, i) => {
      if (!empty) {
        return acc;
      }

      if (releasedDate) {
        if (releasedDate + INTERVAL_BASIS <= Date.now()) {
          acc.push(i);
        }
      } else {
        acc.push(i);
      }

      return acc;
    }, []);
    const index = indexes[Math.floor(Math.random() * indexes.length)];
    Object.assign(this.holes[index], {
      ...mole,
      empty: false,
      releaseDate: this.hideoutTimeRatio * this.getNextTimeout() + Date.now(),
      releasedDate: undefined,
    });

    this.nextFillDate = Date.now() + this.getNextTimeout();
    this.records.push({ ...mole });
  }

  release(hole) {
    Object.assign(hole, {
      empty: true,
      correct: false,
      image: this.hole,
      releasedDate: Date.now(),
    });
  }

  render() {
    if (this.nextFillDate <= Date.now()) {
      this.fill();
    }

    this.context.clearRect(0, 0, this.width, this.height);
    this.holes.forEach((hole) => {
      const { image, left, top, width, releaseDate, releasedDate } = hole;
      this.context.drawImage(image, left, top, width, width / this.aspect);
      if (!releasedDate && releaseDate <= Date.now()) {
        this.release(hole);
      }
    });

    this.requestId = requestAnimationFrame(() => this.render());
  }

  select({ clientX, clientY }) {
    const hole = this.holes.find((hole) => {
      const { left, top, width, height } = hole;
      return left <= clientX - this.offsetLeft
        && left + width >= clientX - this.offsetLeft
        && top < clientY - this.offsetTop
        && top + height >= clientY - this.offsetTop;
    });

    if (!hole || hole.empty) {
      return;
    }

    this.results.push({ ...hole });
    explode(
      this.offsetLeft + hole.left + hole.width / 2,
      this.offsetTop + hole.top + hole.width / this.aspect / 2,
      hole.width / 2,
      hole.width / 2,
    );
    this.release(hole);
  }

  destroy() {
    cancelAnimationFrame(this.requestId);
  }
}
