import Vue from '@vue';

const MAX_WHILE_PROTECTION = 100000;
const createRecords = ($el, amount, object) => {
  const { offsetWidth, offsetHeight } = $el;
  let protection = 0;

  return new Promise((resolve) => {
    const image = new Image();
    image.src = object.object_image;
    image.onload = () => {
      const aspect = image.width / image.height;
      while (state.records.length < amount) {
        createRecord(offsetWidth, offsetHeight, aspect, object);
        protection++;

        if (protection > MAX_WHILE_PROTECTION) {
          break;
        }
      }

      resolve();
    };
  });
};
const createRecord = (width, height, aspect, object) => {
  const radiusX = (width < height ? 0.2 : 0.1) * width;
  const radiusY = width < height ? 0.15 * height : 0.1 * width;
  const rangeX = [0, width - radiusX];
  const rangeY = [0, height - radiusY];
  const _width = width < height ? 0.15 * width : 0.2 * height;
  const _height = _width / aspect;
  let overlapping = false;

  const record = {
    x: Math.random() * (rangeX[1] - rangeX[0]) + rangeX[0],
    y: Math.random() * (rangeY[1] - rangeY[0]) + rangeY[0],
    width: _width,
    height: _height,
    r: Math.sqrt(Math.pow(_width / 2, 2) + Math.pow(_height / 2, 2)),
    object,
  };

  state.records.forEach((_record) => {
    const distance = Math.sqrt(Math.pow(_record.x - record.x, 2) + Math.pow(_record.y - record.y, 2));
    if (distance < _record.r + record.r) {
      overlapping = true;
    }
  });

  if (!overlapping) {
    state.records.push(record);
  }
};

export const initState = ({ $el, amount, active, objects, records }) => {
  Vue.set(state, 'records', []);

  return new Promise((resolve) => {
    if (records) {
      Vue.set(state, 'records', records);
      resolve();
    }

    createRecords($el, active, objects.find(({ correct }) => correct))
      .then(() => createRecords($el, amount, objects.find(({ correct }) => !correct)))
      .then(resolve);
  });
};

const state = Vue.observable({
  records: [],
});

export default state;
