<template>
  <div class="memory__view memory__view--box">
    <template v-if="startTime">
      <img
          v-for="record in state.records"
          :src="record.object.object_image"
          :style="getStyle(record)"
          class="memory__view-item--box"
      />
    </template>
  </div>
</template>
<script>
import state, { initState } from '@/components/ui-memory/state';
import { shuffle } from '@/utils';

export default {
  props: {
    elementsToRemember: Number,
    elementsTotal: Number,
    objects: Array,
  },
  data() {
    return {
      state,
      records: [],
      startTime: undefined,
    };
  },
  computed: {
    correct() {
      return this.objects.find(({ correct }) => correct);
    },
    uncorrect() {
      return this.objects.find(({ correct }) => !correct);
    },
  },
  created() {
    for (let i = 0; i < this.elementsToRemember; i++) {
      this.records.push({ object: this.correct });
    }

    for (let i = this.records.length; i < this.elementsTotal; i++) {
      this.records.push({ object: this.uncorrect });
    }

    this.records = this.shuffle(this.records);
  },
  mounted() {
    this.startTime = Date.now();
    initState({ records: this.records });
  },
  methods: {
    getStyle() {
      const { offsetWidth, offsetHeight } = this.$el;

      return {
        [offsetWidth < offsetHeight ? 'width' : 'height']: `${Math.floor(100 / Math.sqrt(this.elementsTotal))}%`,
      };
    },
    shuffle,
  },
};
</script>
