<template>
  <div class="view view--match">
    <ui-headline v-if="record.headline" :record="record.headline"/>
    <ui-counter :amount="amount" :record="record.headline"/>
    <div class="view__body-container" :style="style">
      <div class="view__quest">
        <img v-if="questImage" :src="questImage"/>
        <h1 v-else-if="quest" v-html="quest"></h1>
      </div>
      <div v-if="answers" :style="{ 'background-color': backgroundColor }" class="view__answers">
        <div v-for="answer in answers" class="view__answer-container">
          <div :style="{ 'background-color': optionsBackgroundColor }"
              class="view__answer"
              @click="$root.setAnswer({ correct: answer.correct }, true)">
            <img v-if="answer.answer_image" class="view__answer-image" :src="answer.answer_image"/>
            <h1 v-else :style="{ 'color': textColor }" v-text="answer.answer_name"></h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { StyleMixin, TimeMixin } from '@/mixins';
import UiCounter from '@/components/ui-counter/index.vue';
import UiHeadline from '@/components/ui-headline/index.vue';
import { addQuest } from '@/app/state';

export default {
  components: {
    UiCounter,
    UiHeadline,
  },
  mixins: [
    StyleMixin,
    TimeMixin,
  ],
  props: {
    record: {
      type: Object,
      required: true,
    },
    amount: Number,
  },
  data() {
    const { quest, questImage } = this.record.body;
    const { answers, backgroundColor, optionsBackgroundColor, textColor } = this.record.footer;

    return {
      answers,
      backgroundColor,
      optionsBackgroundColor,
      textColor,
      quest,
      questImage,
    };
  },
  async mounted() {
    addQuest({ testId: this.record.testId, type: this.record.type });

    await this.$nextTick();
    const { offsetWidth, offsetHeight } = this.$el;
    this.$el.classList.add(offsetWidth > offsetHeight ? 'view--landscape' : 'view--portrait');
  },
};
</script>
