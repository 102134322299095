<template>
  <component :is="layout" :record="active" :amount="amount"/>
</template>
<script>
import { INTRO, MATCH, MATH, MEMORY, MOLE, FRUITS, PRECISION } from '@/enums/task-type';
import { UiIntro, UiMatch, UiMemory, UiMole, UiFruits, UiPrecision } from '@/components';
import { skipGame, skipGroup, openGroup, closeGroup, results } from '@/app/state';
import { TimeoutTimer, pause, resume } from '@/libs/timer';
import { imagesPreload } from '@/utils';

export default {
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      difficultyGroups: this.group.difficultyGroups,
      amount: 0,
      active: undefined,
      last: undefined,
      timer: undefined,
    };
  },
  computed: {
    layout() {
      switch (this.active?.type) {
        case INTRO:
          return UiIntro;
        case MATCH:
        case MATH:
          return UiMatch;
        case MEMORY:
          return UiMemory;
        case MOLE:
          return UiMole;
        case FRUITS:
          return UiFruits;
        case PRECISION:
          return UiPrecision;
        default:
          break;
      }
    },
  },
  watch: {
    $state: {
      deep: true,
      immediate: true,
      async handler() {
        this.last = this.active;
        this.active = undefined;
        await this.$nextTick();
        if (!window.DEBUG || this.last?.type === INTRO) {
          return this.setActive();
        }

        this.$root.showSummary();
        const unwatch = this.$watch(
          () => {
            return this.$root.isSummaryVisible;
          },
          () => {
            if (!this.$root.isSummaryVisible) {
              this.setActive();
              unwatch();
            }
          },
        );
      },
    },
  },
  beforeDestroy() {
    this.timer?.destroy();
    closeGroup();
  },
  mounted() {
    this.amount = this.group.elements.filter(({ type }) => type !== INTRO).length;
    if (this.group.groupQuestTime) {
      this.timer = new TimeoutTimer(skipGroup, this.group.groupQuestTime * 1000);
    }

    openGroup();
  },
  methods: {
    async setActive() {
      const active = this.group.elements[0];
      if (!active) {
        return this.$root.skip();
      }

      const isAvailable = this.checkAvailability(active);
      if (!isAvailable) {
        return skipGame();
      }

      await this.preload();
      this.active = this.group.elements[0];
    },
    preload() {
      pause();
      document.querySelector('.loader').classList.remove('loader--hidden');
      return imagesPreload(this.group.elements[0])
        .then(() => {
          document.querySelector('.loader').classList.add('loader--hidden');
          resume();
        });
    },
    checkAvailability({ difficulty }) {
      const result = this.getResult();
      if (isNaN(result) || !difficulty) {
        return true;
      }

      return result >= (this.difficultyGroups[difficulty] || 0);
    },
    getResult() {
      const current = results[results.length - 1];
      if (!current?.quests.length) {
        return;
      }

      const records = current.quests.map((quest) => this.getCurrentResult(quest));
      return records.reduce((acc, value) => acc + value) / records.length;
    },
    getCurrentResult(quest) {
      const { correct, correctSelected, uncorrectSelected, correctRecords, records } = quest;
      switch (quest.type) {
        case MATCH:
        case MATH:
          return correct ? 100 : 0;
        case MEMORY:
          return Math.max(0, (correctSelected / records) * 100 - (uncorrectSelected / records) * 100);
        case MOLE:
          return Math.max(0, (correctSelected / correctRecords) * 100 - (uncorrectSelected / records) * 100);
        case PRECISION:
          return correct;
      }
    },
  },
};
</script>
