import scan from 'object-scan';

export default (quest) => {
  const images = scan(['**.*Image', '**.*Img', '**.*image', '**.*img'], { rtn: 'value' })(quest)
    .filter(Boolean);

  return Promise.all(images.map((src) => new Promise((resolve) => {
    const image = new Image();
    image.onload = resolve();
    image.onerror = resolve();
    image.src = src;
  })));
};
