<template>
  <div v-if="record"
    :style="style"
    class="view__footer"
    @click="$root.skip()"
  >
    <a v-if="record.shareButtonUrl" :href="record.shareButtonUrl" v-text="record.shareButtonText"></a>
    <a v-if="record.nextButtonUrl" :href="record.nextButtonUrl" v-text="title"></a>
    <template v-else>
      {{ title }}
    </template>
  </div>
</template>
<script>
export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    style() {
      return {
        'background-color': this.record.backgroundColor,
        'color': this.record.textColor,
        'justify-content': this.record.shareButtonUrl ? 'space-between' : 'flex-end',
      };
    },
    title() {
      return this.record.nextButtonText || 'Start';
    },
  },
};
</script>
