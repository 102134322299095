import Vue from '@vue';
import App from '@/app/index.vue';
import http from '@/libs/http';
import state, { setState } from '@/app/state';

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== 'production';
Vue.prototype.$state = state;

const { TEST, TEST_ID } = window;
const promise = TEST
  ? Promise.resolve({ data: typeof TEST === 'string' ? JSON.parse(TEST) : TEST })
  : http.get(`/cognifulltest/${TEST_ID || '1'}`);

promise.then(({ data }) => {
  document.querySelector('.loader').classList.add('loader--hidden');
  setState(data);

  new App().$mount('#app');
});
